import { SectionHeading } from "../SectionHeading/SectionHeading";
import ContactModal from "../Modal/ContactModal";
import { useState } from "react";
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  CircularProgress,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useForm } from "react-hook-form";
import axios from "axios";
import styles from "./Contact.module.scss";

type props = {
  contactRef: any;
};

export default function Contact({ contactRef }: props) {
  const { register, handleSubmit, reset } = useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const onSubmit = async (values: object) => {
    setIsLoading(true);
    const vals = { ...values };
    try {
      await axios.post(
        `https://zuech-dev-server-production.up.railway.app/contact`,
        vals
      );
      setIsSuccessful(true);
      setIsOpen(true);
      reset();
      setChecked(false);
      setIsLoading(false);
    } catch (err) {
      setIsOpen(true);
      setIsSuccessful(false);
    }
  };

  const textFieldStyles = {
    ".MuiInputBase-root": {
      fontSize: "1.6rem",
      "@media screen and (max-width: 727px)": {
        fontSize: "1.4rem",
      },
    },
    color: "#121e27",
    backgroundColor: "#b0c3df",
    borderColor: "#121e27",
    borderRadius: ".4rem",
    "& label": {
      backgroundColor: "#121e27",
      color: "#b0c3df",
      padding: ".4rem .8rem",
      left: "-.4rem",
      top: "-.8rem",
      fontSize: "1.6rem",
      width: "auto",
      borderRadius: ".4rem",
      "@media screen and (max-width: 727px)": {
        fontSize: "1.4rem",
      },
      "&.Mui-focused": {
        color: "#fffd00",
        border: "soldi .2rem #fffd00",
        backgroundImage: "none",
      },
    },
  };

  const buttonStyles = {
    backgroundColor: "#121e27",
    color: "#fffd00",
    fontSize: "1.4rem",
    borderColor: "#fffd00",
    "&:hover": {
      borderColor: "#73d7ee",
      backgroundColor: "#121e27",
    },
    "&.Mui-disabled": {
      color: "#888",
      borderColor: "#888",
    },
  };

  return (
    <section className={styles.section} id="contact" ref={contactRef}>
      <ContactModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isSuccessful={isSuccessful}
        setIsSuccessful={setIsSuccessful}
      />
      <div className={styles.section__container}>
        <div className={styles.section__heading}>
          <SectionHeading headingText="Contact" />
        </div>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <TextField
            {...register("name")}
            className={styles.form__input}
            id="username"
            label="Full Name"
            variant="outlined"
            fullWidth={true}
            sx={textFieldStyles}
            required={true}
            placeholder="Anthony Zuech"
          />
          <TextField
            {...register("email")}
            className={styles.form__input}
            id="email"
            label="Email"
            variant="outlined"
            fullWidth={true}
            sx={textFieldStyles}
            required={true}
            placeholder="address@email.com"
          />
          <TextField
            {...register("message")}
            className={styles.form__input}
            id="message"
            label="Leave me a message"
            variant="outlined"
            fullWidth={true}
            multiline={true}
            minRows={5}
            sx={textFieldStyles}
            required={true}
            placeholder="Here's a tidy little box for your message!"
          />
          <div className={styles.form__inputCheck}>
            <FormControlLabel
              value="Human, they claim!"
              label="Be honest, are you a REAL human?"
              labelPlacement="start"
              sx={{
                ".MuiFormControlLabel-label": {
                  font: "inherit",
                  fontSize: "1.6rem",
                },
              }}
              control={
                <Checkbox
                  id="check"
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{
                    ".MuiSvgIcon-root": {
                      fill: "#fffd00",
                      width: "2.4rem",
                      height: "2.4rem",
                    },
                  }}
                  required
                />
              }
            />
          </div>
          {!isLoading ? (
            <Button
              disabled={!checked}
              variant="outlined"
              size="large"
              endIcon={<SendIcon />}
              type="submit"
              sx={buttonStyles}
            >
              Submit
            </Button>
          ) : (
            <CircularProgress />
          )}
        </form>
      </div>
    </section>
  );
}
