import Divider from "../Divider/Divider";

import styles from "./SectionHeading.module.scss";

type props = {
  headingText: string;
};

export function SectionHeading({ headingText }: props) {
  return (
    <header className={styles.section__header}>
      <h2 className={styles.section__heading}>{headingText}</h2>
      <Divider />
    </header>
  );
}
