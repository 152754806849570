import { SectionHeading } from "../SectionHeading/SectionHeading";

import styles from "./About.module.scss";

type props = {
  aboutRef: any;
};

export default function About({ aboutRef }: props) {
  const headingText: string = "About Me";

  return (
    <section className={styles.section} id="about" ref={aboutRef}>
      <div className={styles.section__container}>
        <div className={styles.about}>
          <SectionHeading headingText={headingText} />
          <main className={styles.about__content}>
            <p className={styles.about__copy}>
              Hi! I'm a trained Full Stack Web Developer with experience in
              ReactJS, relational databases, Prisma ORM, ExpressJS, Javascript,
              & SASS. I am continuing to develop my existing skills and
              expanding my understanding of web development including studying
              object-oriented programming languages (including C# in the context
              of ASP.NET). I graduated from BrainStation's Full Stack Web
              Development bootcamp in Nov. 2022 and I'm currently interning as a
              Junior Web Developer with Inertia Resources, Inc.
            </p>
          </main>
        </div>
        <div className={styles.section__imgWrapper}>
          <div className={styles.section__imgContainer}>
            <div className={styles.section__img} />
          </div>
        </div>
      </div>
    </section>
  );
}
