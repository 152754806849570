import { Box } from "@mui/material";
import { useSwipeable } from "react-swipeable";

type props = {
  activeIndex: number;
  updateIndexHandler: any;
  images: string[];
  handleOpen: any;
  minHeight?: string | null;
  minWidth?: string | null;
};

export default function ImageContainer({
  activeIndex,
  updateIndexHandler,
  images,
  handleOpen,
  minHeight,
  minWidth,
}: props) {
  const handleSwipe = useSwipeable({
    onSwipedLeft: () => updateIndexHandler(activeIndex + 1),
    onSwipedRight: () => updateIndexHandler(activeIndex - 1),
    onSwiped: (e) => console.log(e),
  });

  return (
    <Box
      onClick={handleOpen}
      {...handleSwipe}
      sx={{
        position: "relative",
        overflow: "hidden",
        width: "100%",
        borderRadius: ".4rem",
        transition: "all .4s",
        minHeight: `${minHeight || "30rem"}`,
        minWidth: `${minWidth || null}`,
        backgroundColor: "rgba(0, 0, 0, .2)",
        boxShadow: "0.2rem 0.2rem 0.4rem rgba(0, 0, 0, 0.25)",
        cursor: "pointer",
      }}
    >
      {images.map((image: any, i) => {
        return (
          <>
            <img
              key={i}
              src={image.path}
              alt={image.alt}
              style={{
                padding: "2rem",
                height: "auto",
                maxHeight: "100%",
                width: "auto",
                maxWidth: "100%",
                position: "absolute",
                top: "50%",
                left: "50%",
                transition: "all .7s ease-out",
                opacity: i === activeIndex ? 100 : 0,
                transform: "translate(-50%, -50%)",
              }}
            />
          </>
        );
      })}
    </Box>
  );
}
