import logo from "../../assets/logo/z.png";
import { formatUpperCase } from "../../utilities/formatText";
import Navigation from "../Navigation/Navigation";

import styles from "./Header.module.scss";

type props = {
  aboutRef: any;
  portfolioRef: any;
  expRef: any;
  contactRef: any;
};

export default function Header({
  aboutRef,
  portfolioRef,
  expRef,
  contactRef,
}: props) {
  return (
    <header className={styles.header}>
      <div className={styles.header__container}>
        <div className={styles.header__logo}>
          <img
            src={logo}
            alt="Zuech.dev logo"
            className={styles.header__logoImg}
          />
        </div>
        <div className={styles.header__heading}>
          <h1 className={styles.header__title}>
            {formatUpperCase("Anthony Zuech", styles)}
          </h1>
          <h2 className={styles.header__subtitle}>Full Stack Developer</h2>
        </div>
        <Navigation
          aboutRef={aboutRef}
          portfolioRef={portfolioRef}
          expRef={expRef}
          contactRef={contactRef}
        />
      </div>
    </header>
  );
}
