import { SectionHeading } from "../SectionHeading/SectionHeading";
import { Project } from "./Project/Project";
import Divider from "../Divider/Divider";

import styles from "./Portfolio.module.scss";

import ri1 from "../../assets/images/projects/recipeIndex/index.jpg";
import ri2 from "../../assets/images/projects/recipeIndex/recipe.jpg";
import ri3 from "../../assets/images/projects/recipeIndex/recipeDesktop.jpg";
import sap1 from "../../assets/images/projects/spotAPotty/desktop.jpg";
import sap2 from "../../assets/images/projects/spotAPotty/tablet.jpg";
import mil1 from "../../assets/images/projects/millionaireGame/q1.jpg";
import mil2 from "../../assets/images/projects/millionaireGame/q2.jpg";

type props = {
  portfolioRef: any;
};

export default function Portfolio({ portfolioRef }: props) {
  const projects = [
    {
      title: "Recipe Index",
      description:
        "A full stack web application using React, NodeJS/ExpressJS, PrismaJS, and MySQL. Displays a user's collection of recipes stored in a relational database with consistent formatting and the option to share recipes with another user. In the recipe index, a user can search through their recipes by title and ingredient keywords.",
      images: [
        {
          path: ri1,
          alt: "Screenshot of a user's recipe index on mobile",
        },
        {
          path: ri2,
          alt: "Screenshot of a recipe on mobile",
        },
        {
          path: ri3,
          alt: "Screenshot of a recipe on desktop",
        },
      ],
      links: [
        "https://github.com/zuechai/recipe-index-client",
        "https://github.com/zuechai/recipe-index-api",
      ],
    },
    {
      title: "Spot-a-Potty",
      description:
        "This app was built during a hackathon at BrainStation as an exercise in paired-programming. We designed a simple React app using external web APIs to populate a map, find and list nearby bathrooms, and mark the location of the bathroom on the map. The user can use the form input to search for bathrooms near the submitted location. The map uses Geoaplify and MapLibre APIs and the bathroom locations are pulled from Refuge Bathrooms API.",
      images: [
        {
          path: sap1,
          alt: "Screenshot of a Spot-a-Potty on desktop",
        },
        {
          path: sap2,
          alt: "Screenshot of a Spot-a-Potty on tablet",
        },
      ],
      links: ["https://github.com/zuechai/bathroom-hackathon"],
    },
    {
      title: "Who Wants to Be a Millionaire",
      description:
        "An early team project designed in the style of a hackathon, we were given 4 hours to build a dynamic website using Document Object Model API and Axios for HTTP requests. A majority of my contribution was designing and implementing the interface. It was a really fun challenge to push myself and to see what my team was capable of after 4 weeks at BrainStation.",
      images: [
        {
          path: mil1,
          alt: "Screenshot of the initial load",
        },
        {
          path: mil2,
          alt: "Screenshot of another question showing the score tracker and button's selected state",
        },
      ],
      links: ["https://github.com/zuechai/millionaire-game"],
    },
  ];

  return (
    <section className={styles.section} id="portfolio" ref={portfolioRef}>
      <div className={styles.section__container}>
        <SectionHeading headingText="Portfolio" />
        <main>
          {projects.map((project: any, i) => {
            if (i !== projects.length - 1) {
              return (
                <>
                  <Project key={`p${i}`} project={project} />
                  <Divider key={`d${i}`} />
                </>
              );
            } else {
              return (
                <>
                  <Project project={project} />
                </>
              );
            }
          })}
        </main>
      </div>
    </section>
  );
}
