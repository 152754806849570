import { useState } from "react";
import { Box } from "@mui/material";
import ImageModal from "../Modal/ImageModal";
import ArrowButton from "./CarouselComponents/ArrowButton";
import ImageContainer from "./CarouselComponents/ImageContainer";

type props = {
  images: string[];
};

export default function Carousel({ images }: props) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const updateIndexHandler = (newIndex: number) => {
    if (newIndex < 0) {
      newIndex = images.length - 1;
    } else if (newIndex >= images.length) {
      newIndex = 0;
    }
    setActiveIndex(newIndex);
  };

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box
      component="div"
      sx={{
        borderRadius: ".4rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: ".8rem",
        width: "100%",
      }}
    >
      <ImageModal
        isOpen={isOpen}
        handleOpen={handleOpen}
        images={images}
        activeIndex={activeIndex}
        updateIndexHandler={updateIndexHandler}
      />
      <ArrowButton
        direction="left"
        activeIndex={activeIndex}
        updateIndexHandler={updateIndexHandler}
      />
      <ImageContainer
        activeIndex={activeIndex}
        updateIndexHandler={updateIndexHandler}
        images={images}
        handleOpen={handleOpen}
      />
      <ArrowButton
        direction="right"
        activeIndex={activeIndex}
        updateIndexHandler={updateIndexHandler}
      />
    </Box>
  );
}
