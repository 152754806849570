// import React, { useState } from "react";
import { Zoom, Box, Button, IconButton, Modal } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import styles from "./Navigation.module.scss";
import { scrollToSection } from "../../utilities/scrollUtils";
import { useState } from "react";

type props = {
  aboutRef: any;
  portfolioRef: any;
  expRef: any;
  contactRef: any;
};

export default function Navigation({
  aboutRef,
  portfolioRef,
  expRef,
  contactRef,
}: props) {
  const [openMenu, setOpenMenu] = useState(false);

  const handleClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    ref: React.ClassAttributes<HTMLElement> | undefined
  ) => {
    e.preventDefault();
    scrollToSection(ref);
    if (openMenu) {
      setOpenMenu(false);
    }
  };

  const handleOpenMenu = () => {
    setOpenMenu(true);
  };

  const handleClose = () => {
    setOpenMenu(false);
  };

  const buttonStyles = {
    font: "inherit",
    fontSize: "1.8rem",
    lineHeight: "2.1rem",
    color: "#fff",
    transition: "color 0.4s",
    "&:hover, &:active": {
      color: "#73d7ee",
      backgroundColor: "transparent",
    },
    "@media screen and (max-width: 835px)": {
      fontSize: "1.6rem",
    },
    "@media screen and (max-width: 815px)": {
      fontSize: "2.4rem",
      padding: ".8rem",
    },
  };

  const modalStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const boxStyles = {
    bgcolor: "#121e27",
    border: "2px solid #fffd00",
    borderRadius: ".4rem",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const links = (
    <>
      <Button
        sx={buttonStyles}
        className={`${styles.nav__link}`}
        href="#about"
        onClick={(e) => {
          handleClick(e, aboutRef);
        }}
      >
        About
      </Button>
      <Button
        sx={buttonStyles}
        className={`${styles.nav__link}`}
        href="#porfolio"
        onClick={(e) => {
          handleClick(e, portfolioRef);
        }}
      >
        Portfolio
      </Button>
      <Button
        sx={buttonStyles}
        className={`${styles.nav__link}`}
        href="#experience"
        onClick={(e) => {
          handleClick(e, expRef);
        }}
      >
        Experience
      </Button>
      <Button
        sx={buttonStyles}
        className={`${styles.nav__link}`}
        href="#contact"
        onClick={(e) => {
          handleClick(e, contactRef);
        }}
      >
        Contact
      </Button>
    </>
  );

  const modalNav = (
    <Modal open={openMenu} onClose={handleClose} sx={modalStyles}>
      <Zoom in={openMenu}>
        <Box sx={{ ...boxStyles }}>{links}</Box>
      </Zoom>
    </Modal>
  );

  return (
    <>
      <nav className={styles.nav}>{links}</nav>
      <div className={styles.mobileNav}>
        <IconButton sx={{ ...buttonStyles }} onClick={handleOpenMenu}>
          <MenuIcon sx={{ width: "4rem", height: "4rem" }} />
        </IconButton>
      </div>
      {modalNav}
    </>
  );
}
