import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

type props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isSuccessful: boolean;
  setIsSuccessful: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ContactModal({
  isOpen,
  setIsOpen,
  isSuccessful,
  setIsSuccessful,
}: props) {
  const handleClose = () => {
    setIsOpen(false);
    if (isOpen === false) {
      setIsSuccessful(false);
    }
  };

  const heading = isSuccessful ? "Submitted Successfully!" : "Failed to Submit";

  const message = isSuccessful
    ? "I'll get back to you as soon as I can! Thanks!"
    : "I'm sorry, but something went wrong. Try again later.";

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#121e27",
    border: "2px solid #fffd00",
    borderRadius: ".4rem",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const buttonStyles = {
    marginTop: "2.4rem",
    backgroundColor: "#121e27",
    color: "#fffd00",
    fontSize: "1.4rem",
    borderColor: "#fffd00",
    "&:hover": {
      borderColor: "#73d7ee",
      backgroundColor: "#121e27",
    },
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={isOpen}>
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h3"
            sx={{ fontSize: "1.8rem", textAlign: "center" }}
          >
            {heading}
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{
              marginTop: "2.4rem",
              fontSize: "1.4rem",
              textAlign: "center",
            }}
          >
            {message}
          </Typography>
          <Button
            onClick={handleClose}
            variant="outlined"
            size="large"
            // endIcon={<SendIcon />}
            type="submit"
            sx={buttonStyles}
            endIcon={<CloseIcon />}
          >
            Close
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
}
