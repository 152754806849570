import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { IconButton } from "@mui/material";

type props = {
  direction: string;
  activeIndex: number;
  updateIndexHandler: any;
};

export default function ArrowButton({
  direction,
  activeIndex,
  updateIndexHandler,
}: props) {
  const carouselIconStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 999,
    color: "#fffd00",
    padding: ".4rem",
    "&:hover": {
      backgroundColor: "rgba(18, 30, 39, .4)",
    },
  };

  return (
    <IconButton
      sx={{
        ...carouselIconStyles,
      }}
      aria-label="Left arrow"
      onClick={() => updateIndexHandler(activeIndex - 1)}
    >
      {(direction === "left" && (
        <KeyboardArrowLeftIcon sx={{ fontSize: "3rem" }} />
      )) ||
        (direction === "right" && (
          <KeyboardArrowRightIcon sx={{ fontSize: "3rem" }} />
        ))}
    </IconButton>
  );
}
