import GitHubIcon from "@mui/icons-material/GitHub";
import { IconButton } from "@mui/material";
import Carousel from "../../Carousel/Carousel";
import openInNewTab from "../../../utilities/clickUtils";
import styles from "./Project.module.scss";

type props = {
  project: any;
};

export function Project({ project }: props) {
  const iconLinkStyles = {
    padding: ".4rem",
    color: "#121e27",
    backgroundColor: "#fff",
    transition: "all .3s",
    "&:hover": {
      color: "#fffd00",
      backgroundColor: "#121e27",
    },
  };

  return (
    <article className={styles.project}>
      <div className={styles.project__mainContent}>
        <header className={styles.project__header}>
          <h3 className={styles.project__heading}>{project.title}</h3>
          <div className={styles.project__icons}>
            {project.links.map((link: string, i: number) => {
              return (
                <IconButton
                  key={i}
                  aria-label="Github"
                  sx={iconLinkStyles}
                  href={link}
                  onClick={(e) => {
                    e.preventDefault();
                    openInNewTab(link);
                  }}
                >
                  <GitHubIcon fontSize={"large"} />
                </IconButton>
              );
            })}
          </div>
        </header>
        <div className={styles.project__description}>{project.description}</div>
      </div>
      <div className={styles.project__mainImg}>
        <Carousel images={project.images} />
      </div>
    </article>
  );
}
