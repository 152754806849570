import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import ArrowButton from "../Carousel/CarouselComponents/ArrowButton";
import ImageContainer from "../Carousel/CarouselComponents/ImageContainer";

type props = {
  isOpen: boolean;
  handleOpen: any;
  images: string[];
  activeIndex: number;
  updateIndexHandler: any;
};

export default function ContactModal({
  isOpen,
  handleOpen,
  images,
  activeIndex,
  updateIndexHandler,
}: props) {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90vw",
    height: "90vh",
    bgcolor: "#121e27",
    border: "2px solid #fffd00",
    borderRadius: ".4rem",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "@media screen and (max-width: 840px)": {
      width: "100vw",
      height: "100vh",
    },
  };

  const buttonStyles = {
    position: "absolute",
    right: "0.8rem",
    top: "0.4rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fffd00",
    backgroundColor: "transparent",
    borderColor: "transparent",
    padding: 0,
    minWidth: 0,
    "&:hover": {
      color: "#73d7ee",
      borderColor: "transparent",
      backgroundColor: "transparent",
    },
    ".MuiButton-endIcon": {
      margin: 0,
    },
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        backgroundColor: "#121e27",
        opacity: 1,
      }}
    >
      <Fade in={isOpen}>
        <Box sx={style}>
          <Box
            sx={{
              position: "relative",
              borderRadius: ".4rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: ".4rem",
              width: "80vw",
              "@media screen and (max-width: 840px)": {
                width: "100vw",
                height: "100vh",
              },
            }}
          >
            <ArrowButton
              direction="left"
              activeIndex={activeIndex}
              updateIndexHandler={updateIndexHandler}
            />
            <ImageContainer
              activeIndex={activeIndex}
              updateIndexHandler={updateIndexHandler}
              images={images}
              handleOpen={handleOpen}
              minHeight="80vh"
              minWidth="80vw"
            />
            <ArrowButton
              direction="right"
              activeIndex={activeIndex}
              updateIndexHandler={updateIndexHandler}
            />
          </Box>
          <Button
            onClick={handleOpen}
            variant="outlined"
            size="large"
            sx={buttonStyles}
            endIcon={<CloseIcon />}
          />
        </Box>
      </Fade>
    </Modal>
  );
}
