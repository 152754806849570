export function scrollToTop() {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
}

export function scrollToSection(refs: any) {
  const top = refs.current.offsetTop;
  window.scrollTo({
    top,
    behavior: "smooth",
  });
}
