import Divider from "../Divider/Divider";

import styles from "./Experience.module.scss";

type props = {
  expRef: any;
};

export function Experience({ expRef }: props) {
  return (
    <section className={styles.section} id="experience" ref={expRef}>
      <div className={styles.section__container}>
        <header className={styles.section__header}>
          <div className={styles.section__divLeft}>
            <Divider />
          </div>
          <h2 className={styles.section__heading}>Experience</h2>
          <div className={styles.section__divRight}>
            <Divider />
          </div>
        </header>

        <div className={styles.exp}>
          {/* experience list */}
          <div className={styles.exp__roles}>
            {/* non-contract employment */}
            <div className={styles.exp__segment}>
              <h3 className={styles.exp__heading}>Work Experience</h3>
              <ul className={styles.exp__list}>
                <li key={"c1"} className={styles.exp__company}>
                  <h4 className={styles.exp__name}>Terminix Triad</h4>
                  <p className={styles.exp__description}>Full-time | .NET Web Developer</p>
                  <p className={styles.exp__description}>
                    ASP.NET MVC, jQuery, SQL, Stored Procedures, Payment API Development, UI Design & Implementation, Performance Optimizations
                  </p>
                </li>
                <li key={"c2"} className={styles.exp__company}>
                  <h4 className={styles.exp__name}>Grassroots Grocery</h4>
                  <p className={styles.exp__description}>
                    Internship | Volunteer ExpressJs Developer
                  </p>
                  <p className={styles.exp__description}>
                    Stack: TypeScript, Express, Vite, Airtable, Railway
                  </p>
                </li>
                <li key={"c3"} className={styles.exp__company}>
                  <h4 className={styles.exp__name}>Inertia Resources, Inc.</h4>
                  <p className={styles.exp__description}>
                    Internship | Junior Web Developer
                  </p>
                  <p className={styles.exp__description}>
                    Stack: NextJs, .NET, SQL Server
                  </p>
                </li>
              </ul>
            </div>
            {/* contract employment */}
            <div className={styles.exp__segment}>
              <h3 className={styles.exp__heading}>Freelance Contracts</h3>
              <ul className={styles.exp__list}>
                <li key={"f1"} className={styles.exp__listItem}>
                  <h4 className={styles.exp__name}>Branex Properties</h4>
                  <p className={styles.exp__description}>
                    Rental Property Management
                  </p>
                </li>
                <li key={"f2"} className={styles.exp__listItem}>
                  <h4 className={styles.exp__name}>Wine Down</h4>
                  <p className={styles.exp__description}>
                    Beach Vacation Rental Property
                  </p>
                </li>
              </ul>
            </div>
            {/* education */}
            <div className={styles.exp__segment}>
              <h3 className={styles.exp__heading}>Education</h3>
              <ul className={styles.exp__list}>
                <li key={"e1"} className={styles.exp__listItem}>
                  <h4 className={styles.exp__name}>BrainStation</h4>
                  <p className={styles.exp__description}>
                    Full Stack Web Development
                  </p>
                </li>
                <li key={"e2"} className={styles.exp__listItem}>
                  <h4 className={styles.exp__name}>
                    North Carolina State University
                  </h4>
                  <p className={styles.exp__description}>Java</p>
                </li>
                <li key={"e3"} className={styles.exp__listItem}>
                  <h4 className={styles.exp__name}>Udemy</h4>
                  <p className={styles.exp__description}>
                    HTML, SASS, JavaScript, ReactJs, MySQL, C#
                  </p>
                </li>
                <li key={"e4"} className={styles.exp__listItem}>
                  <h4 className={styles.exp__name}>
                    Appalachian State University
                  </h4>
                  <p className={styles.exp__description}>
                    B.S. Nutrition & Food Systems Management
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div className={styles.exp__divider}>
            <Divider vertical={true} />
          </div>

          {/* skills list */}
          <div className={styles.skills}>
            <h3 className={styles.skills__heading}>Skills List</h3>
            <ul className={styles.skills__list}>
              <li key={"s1"} className={styles.skills__listItem}>
                React
              </li>
              <li key={"s3"} className={styles.skills__listItem}>
                Node | Express | Axios
              </li>
              <li key={"s5"} className={styles.skills__listItem}>
                SQL | Prisma
              </li>
              <li key={"s6"} className={styles.skills__listItem}>
                HTML | CSS
              </li>
              <li key={"s7"} className={styles.skills__listItem}>
                Sass | SCSS | BEM
              </li>
              <li key={"s9"} className={styles.skills__listItem}>
                Git
              </li>
              <li key={"s10"} className={styles.skills__listItem}>
                Netlify | Railway
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
