import { useRef } from "react";
import Header from "../components/Header/Header";
import Navigation from "../components/Navigation/Navigation";
import About from "../components/About/About";
import { Experience } from "../components/Experience/Experience";
import Portfolio from "../components/Portfolio/Portfolio";
import Contact from "../components/Contact/Contact";
import Footer from "../components/Footer/Footer";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { scrollToTop } from "../utilities/scrollUtils";
import { Fade, useScrollTrigger, Box, Fab } from "@mui/material";
import styles from "./LandingPage.module.scss";

export default function LandingPage() {
  const pageTopRef = useRef(null);
  const aboutRef = useRef(null);
  const portfolioRef = useRef(null);
  const expRef = useRef(null);
  const contactRef = useRef(null);

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  return (
    <div className={styles.page} ref={pageTopRef}>
      <Header
        aboutRef={aboutRef}
        portfolioRef={portfolioRef}
        expRef={expRef}
        contactRef={contactRef}
      />
      <About aboutRef={aboutRef} />
      <Portfolio portfolioRef={portfolioRef} />
      <Experience expRef={expRef} />
      <Contact contactRef={contactRef} />
      <Footer />
      <Fade
        in={trigger}
        children={
          <div>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "fixed",
                width: "100%",
                bottom: 0,
                gap: "2.4rem",
                backgroundColor: "rgba(18, 30, 39, .7)",
                padding: "1.6rem 1.6rem",
                borderRadius: ".4rem",
                zIndex: 999,
                "@media screen and (max-width: 815px)": {
                  padding: "1.6rem 3.2rem",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                },
              }}
            >
              <Navigation
                aboutRef={aboutRef}
                portfolioRef={portfolioRef}
                expRef={expRef}
                contactRef={contactRef}
              />
              <Fab
                color="primary"
                aria-label="to-top"
                sx={{
                  transition: "all .3s",
                  width: "3.6rem",
                  height: "3.6rem",
                  backgroundColor: "#6186be",
                  opacity: "100%",
                  "&:hover": {
                    backgroundColor: "#436aa4",
                    opacity: "100%",
                  },
                }}
                size="small"
              >
                <KeyboardArrowUpIcon
                  sx={{
                    width: "3.2rem",
                    height: "3.2rem",
                  }}
                  onClick={() => scrollToTop()}
                />
              </Fab>
            </Box>
          </div>
        }
      />
    </div>
  );
}
