export const formatUpperCase = (
  str: string,
  styles: {
    readonly [x: string]: string;
    firstChar?: any;
    remainingChars?: any;
  }
) => {
  const formattedStr = str.split(" ").map((word) => {
    if (word !== "+") {
      const chars = word.split("");
      const firstChar: string | undefined = chars.shift();
      return (
        <>
          <span className={styles.firstChar}>{firstChar}</span>
          <span className={styles.remainingChars}>{chars} </span>
        </>
      );
    } else {
      return word + " ";
    }
  });
  return formattedStr;
};
