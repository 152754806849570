import styles from "./Divider.module.scss";

type props = {
  vertical?: boolean;
};

export default function Divider({ vertical }: props) {
  if (vertical) {
    return (
      <div
        className={`${styles.dividerVertical} ${styles.dividerVertical__tablet}`}
      />
    );
  } else {
    return <div className={styles.divider} />;
  }
}
