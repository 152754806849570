import logo from "../../assets/logo/z.png";
import { IconButton } from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { formatUpperCase } from "../../utilities/formatText";
import openInNewTab from "../../utilities/clickUtils";

import styles from "./Footer.module.scss";

export default function Footer() {
  const fullYear: number = new Date().getFullYear();

  const iconLinkStyles = {
    padding: ".4rem",
    color: "#121e27",
    backgroundColor: "#fff",
    transition: "all .3s",
    "&:hover": {
      color: "#fffd00",
      backgroundColor: "#121e27",
    },
  };

  const linkedInUrl = "https://www.linkedin.com/in/zuechai/";
  const githubUrl = "https://github.com/zuechai";
  const emailHref = "mailto:zuechai@gmail.com";

  return (
    <footer className={styles.footer}>
      <div className={styles.footer__main}>
        <h2 className={styles.footer__heading}>
          {formatUpperCase("Anthony Zuech", styles)}
        </h2>
        <div className={styles.social}>
          <IconButton
            sx={{ ...iconLinkStyles }}
            href={linkedInUrl}
            onClick={(e) => {
              e.preventDefault();
              openInNewTab(linkedInUrl);
            }}
          >
            <LinkedInIcon fontSize={"large"} />
          </IconButton>
          <IconButton
            sx={{ ...iconLinkStyles }}
            href={githubUrl}
            onClick={(e) => {
              e.preventDefault();
              openInNewTab(githubUrl);
            }}
          >
            <GitHubIcon fontSize={"large"} />
          </IconButton>
          <IconButton
            sx={{ ...iconLinkStyles }}
            href={emailHref}
            onClick={(e) => {
              e.preventDefault();
              openInNewTab(emailHref);
            }}
          >
            <MailOutlineIcon fontSize={"large"} />
          </IconButton>
        </div>
        <div className={styles.copyright}>
          <p className={styles.copyright__copy}>
            <span className={styles.copyright__symbol}>&copy;</span> {fullYear}{" "}
            Anthony Zuech
          </p>
        </div>
      </div>
      <div className={styles.footer__logo}>
        <img
          src={logo}
          alt="Zuech.dev logo"
          className={styles.header__logoImg}
        />
      </div>
    </footer>
  );
}
